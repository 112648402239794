import { BrandDot } from '@/components/BrandDot'
import { SEO } from '@/components/SEO'
import { useTheme } from '@/store'
import { FourOhFourPageQuery } from '@/types/__generated__/FourOhFourPageQuery'
import { fontSmoothing, padding } from '@/utils/mixins'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

export const query = graphql`
  query FourOhFourPageQuery {
    contentfulPage404 {
      title
      heroImage {
        fluid(maxWidth: 1024, quality: 95) {
          ...ContentfulFluid
        }
      }
    }
  }
`

type Props = {
  data: FourOhFourPageQuery
}

const FourOhFour: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const { title, heroImage } = data.contentfulPage404

  return (
    <>
      <SEO title={title} />

      <section
        css={css`
          ${padding};
        `}
      >
        <div
          css={css`
            max-width: ${theme.maxWidth};
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              position: relative;

              border-radius: ${theme.radius.photo};
              overflow: hidden;
              box-shadow: ${theme.elevation[2]};
            `}
          >
            <div
              css={css`
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                padding: 5%;
                padding-left: 10%;

                ${scrimGradient({
                  color: theme.color.grays[8],
                  direction: 'to left',
                  startAlpha: 0.32,
                })};
              `}
            >
              <div
                css={css`
                  max-width: 16rem;
                  height: 100%;

                  display: flex;
                  align-items: flex-end;
                  flex-direction: column;
                  justify-content: space-between;

                  color: ${theme.color.grays[0]};
                  text-shadow: ${theme.textShadow};
                  text-align: right;
                  ${fontSmoothing};

                  ${theme.mq.sm} {
                    display: block;

                    > * + * {
                      margin-top: 2rem;
                    }
                  }
                `}
              >
                <div>
                  <h1
                    css={css`
                      ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
                      font-weight: 700;
                    `}
                  >
                    404
                    <BrandDot />
                  </h1>
                  <p
                    css={css`
                      ${fluidFontSize(16, 24, 320, theme.vwImageCapWidth)};
                      font-weight: 700;
                    `}
                  >
                    Page not found
                  </p>
                </div>

                <p
                  css={css`
                    ${fluidFontSize(16, 24, 320, theme.vwImageCapWidth)};
                    line-height: 1.2;
                  `}
                >
                  Oops, looks like you’ve taken a wrong turn and reached the
                  outside.
                </p>

                <Link
                  to="/"
                  css={css`
                    display: inline-block;
                    padding: 0.75em 1em;
                    border: solid 1px ${theme.color.grays[0]};

                    color: inherit;
                    ${fluidFontSize(12, 16, 320, theme.vwImageCapWidth)};
                    text-transform: lowercase;
                    text-decoration: none;

                    transition: all 0.1s ease-out;

                    &:hover {
                      background: ${theme.color.grays[0]};
                      color: ${theme.color.grays[6]};
                    }
                  `}
                >
                  Back to Home
                </Link>
              </div>
            </div>
            <Img fluid={heroImage.fluid} alt="" />
          </div>
        </div>
      </section>
    </>
  )
}

export default FourOhFour
